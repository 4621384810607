<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-row class="filterSection">
                <b-col md="4">
                    <base-input :label="$t('availability.doctor_list')">
                        <el-select v-model="filter.doctor" filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData">
                            <el-option v-for="(item, index) in doctors" :key="index" :label="item.title+item.name+' - '+item.email+' - '+item.phone" :value="item.uuid"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.patient_search_area')" :placeholder="$t('doctor.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="comments" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.doctor')" width="240">
                    <template v-slot="{row}">
                        {{row.doctor.title}} {{row.doctor.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" width="240">
                    <template v-slot="{row}">
                        {{row.patient.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.rate')" width="110">
                    <template v-slot="{row}">
                        {{row.rate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.publish_status')" width="180">
                    <template v-slot="{row}">
                        {{row.publish ? $t('general.publish') : $t('general.not_publish')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" width="210">
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column align="right" min-width="180">
                    <template slot-scope="scope">
                        <base-button type="primary" size="sm" @click="showContent(scope.row)">
                            {{$t('general.comment_content')}}</base-button>
                        <base-button :type="scope.row.publish?'danger':'success'" size="sm" @click="changePublish(scope.row)">{{scope.row.publish?$t('comment.not_publish'):$t('comment.publish')}}</base-button>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose">
            <b-alert class="posrel" variant="secondary" show v-if="modal.data.uuid!=undefined">
                <strong>{{modal.data.patient.name}}</strong>
                <div class="patient-rate">
                    <b-input-group class="rateInput mb-3">
                        <b-form-rating :value="modal.data.rate" color="#ff8800" inline readonly no-border></b-form-rating>
                    </b-input-group>
                </div>
                <p class="mt-2">
                    <small v-html="$options.filters.getHtmlText(modal.data.comment)"></small>
                </p>
                <small>{{modal.data.createdAt | getDate}}</small>
                <template v-if="modal.data.doctor_comment&&modal.data.doctor_comment!=''">
                    <hr class="mt-2">
                    <strong>{{$t('general.doctor_comment')}} : </strong>
                    <small v-html="$options.filters.getHtmlText(modal.data.doctor_comment)"></small>
                </template>
            </b-alert>
            <hr class="my-2">
            <base-button :type="modal.data.publish?'danger':'success'" size="sm" @click="changePublish(modal.data)">{{modal.data.publish?$t('comment.not_publish'):$t('comment.publish')}}</base-button>
            <base-button type="link" class="text-danger ml-auto float-right" @click="modalClose">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DOCTOR_REQUEST, COMMENT_LIST_REQUEST, COMMENT_UPDATE } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapGetters({ doctors: 'getDoctors', comments: 'getComments', total: 'getCommentTotal', pages: 'getCommentPages' })
    },
    data() {
        return {
            loading: true,
            modal: {
                status: false,
                loading: false,
                data: {}
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {
                text: ''
            },
            timer: null
        };
    },
    methods: {
        changePublish(row) {
            let uuid = row.appointment.uuid;
            let status = row.publish !== true;

            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.change_comment_publish'),
                icon: status ? 'success' : 'warning',
                showCancelButton: true,
                confirmButtonColor: status ? '#2dce89' : '#f5365c',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch(COMMENT_UPDATE, { uuid, comment: { publish: status } })
                        .then(resp => {
                            self.loading = false;
                            self.modalClose();
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal = {
                status: false,
                loading: false,
                data: {}
            };
        },
        showContent(row) {
            self.modal.data = _.cloneDeep(row);
            self.modal.status = true;
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        getDoctors() {
            self.$store.dispatch(DOCTOR_REQUEST, { pagination: { limit: 10000 }, filter: self.filter, fields: '-_id title uuid name email phone' }).catch(err => {
                handleError(self, err);
            });
        },
        getData() {
            self.loading = true;
            self.$store
                .dispatch(COMMENT_LIST_REQUEST, { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData();
            }, 800);
        }
    },
    filters: {},
    created() {
        self = this;
        self.getDoctors();
        self.getData();
    }
};
</script>